/* tslint:disable */
/* eslint-disable */
/**
 * Identity Service API
 * Documentation Launchpad Service API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BasicAuthCredentialsResource
 */
export interface BasicAuthCredentialsResource {
    /**
     * 
     * @type {number}
     * @memberof BasicAuthCredentialsResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BasicAuthCredentialsResource
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicAuthCredentialsResource
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface CompanyResource
 */
export interface CompanyResource {
    /**
     * 
     * @type {number}
     * @memberof CompanyResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResource
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResource
     */
    'phone'?: number;
}
/**
 * 
 * @export
 * @interface DockerServicePropertyResource
 */
export interface DockerServicePropertyResource {
    /**
     * 
     * @type {number}
     * @memberof DockerServicePropertyResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DockerServicePropertyResource
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof DockerServicePropertyResource
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface EnvironmentResource
 */
export interface EnvironmentResource {
    /**
     * 
     * @type {number}
     * @memberof EnvironmentResource
     */
    'id'?: number;
    /**
     * 
     * @type {EnvironmentResource}
     * @memberof EnvironmentResource
     */
    'precedentEnvironment'?: EnvironmentResource;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentResource
     */
    'canDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResource
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ReleaseResource>}
     * @memberof EnvironmentResource
     */
    'releases'?: Array<ReleaseResource>;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResource
     */
    'domain'?: string;
    /**
     * 
     * @type {Array<TagResource>}
     * @memberof EnvironmentResource
     */
    'tags'?: Array<TagResource>;
}
/**
 * 
 * @export
 * @interface EnvironmentVariableResource
 */
export interface EnvironmentVariableResource {
    /**
     * 
     * @type {number}
     * @memberof EnvironmentVariableResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentVariableResource
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentVariableResource
     */
    'value'?: string;
    /**
     * 
     * @type {SecretResource}
     * @memberof EnvironmentVariableResource
     */
    'secret'?: SecretResource;
    /**
     * 
     * @type {ParameterResource}
     * @memberof EnvironmentVariableResource
     */
    'parameter'?: ParameterResource;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentVariableResource
     */
    'isExpression'?: boolean;
}
/**
 * 
 * @export
 * @interface GitConfigurationResource
 */
export interface GitConfigurationResource {
    /**
     * 
     * @type {string}
     * @memberof GitConfigurationResource
     */
    'repository'?: string;
    /**
     * 
     * @type {string}
     * @memberof GitConfigurationResource
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof GitConfigurationResource
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface HealthcheckServicePropertyResource
 */
export interface HealthcheckServicePropertyResource {
    /**
     * 
     * @type {number}
     * @memberof HealthcheckServicePropertyResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof HealthcheckServicePropertyResource
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface IdentityResource
 */
export interface IdentityResource {
    /**
     * 
     * @type {number}
     * @memberof IdentityResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityResource
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityResource
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<RoleResource>}
     * @memberof IdentityResource
     */
    'roles'?: Array<RoleResource>;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityResource
     */
    'superUser'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject2
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject3
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject4
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InviteResource
 */
export interface InviteResource {
    /**
     * 
     * @type {number}
     * @memberof InviteResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InviteResource
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface K8sServicePropertyResource
 */
export interface K8sServicePropertyResource {
    /**
     * 
     * @type {number}
     * @memberof K8sServicePropertyResource
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof K8sServicePropertyResource
     */
    'replicaCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof K8sServicePropertyResource
     */
    'cpuLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof K8sServicePropertyResource
     */
    'memoryLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof K8sServicePropertyResource
     */
    'cpuRequest'?: number;
    /**
     * 
     * @type {number}
     * @memberof K8sServicePropertyResource
     */
    'memoryRequest'?: number;
}
/**
 * 
 * @export
 * @interface OAuth2ServicePropertyResource
 */
export interface OAuth2ServicePropertyResource {
    /**
     * 
     * @type {number}
     * @memberof OAuth2ServicePropertyResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OAuth2ServicePropertyResource
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2ServicePropertyResource
     */
    'clientPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2ServicePropertyResource
     */
    'clientScope'?: string;
}
/**
 * 
 * @export
 * @interface PageIdentityResource
 */
export interface PageIdentityResource {
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<IdentityResource>}
     * @memberof PageIdentityResource
     */
    'content'?: Array<IdentityResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageIdentityResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageIdentityResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageIdentityResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageIdentityResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageIdentityResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    'sort'?: Sort;
}
/**
 * 
 * @export
 * @interface ParameterResource
 */
export interface ParameterResource {
    /**
     * 
     * @type {number}
     * @memberof ParameterResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParameterResource
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterResource
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterResource
     */
    'canDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface PathServicePropertyResource
 */
export interface PathServicePropertyResource {
    /**
     * 
     * @type {number}
     * @memberof PathServicePropertyResource
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PathServicePropertyResource
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof PathServicePropertyResource
     */
    'host'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PathServicePropertyResource
     */
    'redirectWWW'?: boolean;
    /**
     * 
     * @type {BasicAuthCredentialsResource}
     * @memberof PathServicePropertyResource
     */
    'basicAuthCredentials'?: BasicAuthCredentialsResource;
    /**
     * 
     * @type {string}
     * @memberof PathServicePropertyResource
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface PortMappingServicePropertyResource
 */
export interface PortMappingServicePropertyResource {
    /**
     * 
     * @type {number}
     * @memberof PortMappingServicePropertyResource
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortMappingServicePropertyResource
     */
    'port'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortMappingServicePropertyResource
     */
    'containerPort'?: number;
}
/**
 * 
 * @export
 * @interface PrivilegeResource
 */
export interface PrivilegeResource {
    /**
     * 
     * @type {number}
     * @memberof PrivilegeResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrivilegeResource
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProjectIconResource
 */
export interface ProjectIconResource {
    /**
     * 
     * @type {number}
     * @memberof ProjectIconResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectIconResource
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectIconResource
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ProjectResource
 */
export interface ProjectResource {
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'shortName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    'versionMajor'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    'versionMinor'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    'versionPatch'?: number;
    /**
     * 
     * @type {IdentityResource}
     * @memberof ProjectResource
     */
    'owner'?: IdentityResource;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'adminUrl'?: string;
    /**
     * 
     * @type {PrivilegeResource}
     * @memberof ProjectResource
     */
    'adminPrivilege'?: PrivilegeResource;
    /**
     * 
     * @type {ProjectIconResource}
     * @memberof ProjectResource
     */
    'favIcon'?: ProjectIconResource;
    /**
     * 
     * @type {ProjectIconResource}
     * @memberof ProjectResource
     */
    'appleTouchIcon'?: ProjectIconResource;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'themeColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'themeBackgroundColor'?: string;
    /**
     * 
     * @type {ProjectIconResource}
     * @memberof ProjectResource
     */
    'logo'?: ProjectIconResource;
    /**
     * 
     * @type {ProjectIconResource}
     * @memberof ProjectResource
     */
    'icon'?: ProjectIconResource;
    /**
     * 
     * @type {ProjectIconResource}
     * @memberof ProjectResource
     */
    'icon512'?: ProjectIconResource;
    /**
     * 
     * @type {ProjectIconResource}
     * @memberof ProjectResource
     */
    'icon192'?: ProjectIconResource;
    /**
     * 
     * @type {ProjectIconResource}
     * @memberof ProjectResource
     */
    'maskableIcon'?: ProjectIconResource;
    /**
     * 
     * @type {Array<EnvironmentResource>}
     * @memberof ProjectResource
     */
    'environments'?: Array<EnvironmentResource>;
    /**
     * 
     * @type {GitConfigurationResource}
     * @memberof ProjectResource
     */
    'gitConfiguration'?: GitConfigurationResource;
    /**
     * 
     * @type {Array<TagResource>}
     * @memberof ProjectResource
     */
    'tags'?: Array<TagResource>;
    /**
     * 
     * @type {Array<InviteResource>}
     * @memberof ProjectResource
     */
    'invites'?: Array<InviteResource>;
}
/**
 * 
 * @export
 * @interface ReleaseResource
 */
export interface ReleaseResource {
    /**
     * 
     * @type {number}
     * @memberof ReleaseResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReleaseResource
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseResource
     */
    'logs'?: string;
}
/**
 * 
 * @export
 * @interface RoleResource
 */
export interface RoleResource {
    /**
     * 
     * @type {number}
     * @memberof RoleResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleResource
     */
    'name'?: string;
    /**
     * 
     * @type {Array<PrivilegeResource>}
     * @memberof RoleResource
     */
    'privileges'?: Array<PrivilegeResource>;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResource
     */
    'public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleResource
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface SecretResource
 */
export interface SecretResource {
    /**
     * 
     * @type {number}
     * @memberof SecretResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SecretResource
     */
    'key'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecretResource
     */
    'canDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface ServicePropertyResource
 */
export interface ServicePropertyResource {
    /**
     * 
     * @type {number}
     * @memberof ServicePropertyResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicePropertyResource
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ServicePropertyTypeResource
 */
export interface ServicePropertyTypeResource {
    /**
     * 
     * @type {string}
     * @memberof ServicePropertyTypeResource
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicePropertyTypeResource
     */
    'maxCount'?: number;
}
/**
 * 
 * @export
 * @interface ServiceResource
 */
export interface ServiceResource {
    /**
     * 
     * @type {number}
     * @memberof ServiceResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ServiceResource>}
     * @memberof ServiceResource
     */
    'dependencies'?: Array<ServiceResource>;
    /**
     * 
     * @type {Array<ServiceResource>}
     * @memberof ServiceResource
     */
    'otherDependencies'?: Array<ServiceResource>;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResource
     */
    'canDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'type'?: ServiceResourceTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceResourceTypeEnum {
    Standard = 'STANDARD',
    MasterGateway = 'MASTER_GATEWAY',
    SlaveGateway = 'SLAVE_GATEWAY',
    Gui = 'GUI'
}

/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface SubscriptionResource
 */
export interface SubscriptionResource {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionResource
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionResource
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionResource
     */
    'type'?: SubscriptionResourceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionResource
     */
    'isSubscriptionDisabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionResource
     */
    'subscriptionDisabled'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionResourceTypeEnum {
    Trial = 'TRIAL',
    Pro = 'PRO',
    Launchpad = 'LAUNCHPAD'
}

/**
 * 
 * @export
 * @interface TagResource
 */
export interface TagResource {
    /**
     * 
     * @type {number}
     * @memberof TagResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagResource
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserResource
 */
export interface UserResource {
    /**
     * 
     * @type {number}
     * @memberof UserResource
     */
    'id'?: number;
    /**
     * 
     * @type {IdentityResource}
     * @memberof UserResource
     */
    'identityResource'?: IdentityResource;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'title'?: UserResourceTitleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'state'?: UserResourceStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'lastname'?: string;
    /**
     * 
     * @type {SubscriptionResource}
     * @memberof UserResource
     */
    'subscription'?: SubscriptionResource;
}

/**
    * @export
    * @enum {string}
    */
export enum UserResourceTitleEnum {
    SoftwareEngineer = 'SOFTWARE_ENGINEER',
    Freelance = 'FREELANCE',
    Entrepreneur = 'ENTREPRENEUR',
    Architect = 'ARCHITECT',
    ChiefTechnologyOfficer = 'CHIEF_TECHNOLOGY_OFFICER',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum UserResourceStateEnum {
    Created = 'CREATED',
    Activated = 'ACTIVATED'
}

/**
 * 
 * @export
 * @interface VolumeResource
 */
export interface VolumeResource {
    /**
     * 
     * @type {number}
     * @memberof VolumeResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VolumeResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VolumeResource
     */
    'mount'?: string;
    /**
     * 
     * @type {string}
     * @memberof VolumeResource
     */
    'size'?: string;
}

/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {boolean} [isExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironmentVariable: async (serviceId: number, key: string, value?: string, secretId?: number, parameterId?: number, isExpression?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('createEnvironmentVariable', 'serviceId', serviceId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('createEnvironmentVariable', 'key', key)
            const localVarPath = `/admin/service/{serviceId}/env`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (secretId !== undefined) {
                localVarQueryParameter['secret_id'] = secretId;
            }

            if (parameterId !== undefined) {
                localVarQueryParameter['parameter_id'] = parameterId;
            }

            if (isExpression !== undefined) {
                localVarQueryParameter['isExpression'] = isExpression;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParameter: async (serviceId: number, key: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('createParameter', 'serviceId', serviceId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('createParameter', 'key', key)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('createParameter', 'value', value)
            const localVarPath = `/admin/service/{serviceId}/parameter`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrivileges: async (projectId: number, names: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createPrivileges', 'projectId', projectId)
            // verify required parameter 'names' is not null or undefined
            assertParamExists('createPrivileges', 'names', names)
            const localVarPath = `/admin/project/{projectId}/privileges`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (names) {
                localVarQueryParameter['names'] = names;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [adminUrl] 
         * @param {string} [shortname] 
         * @param {string} [themeColor] 
         * @param {string} [themeBackgroundColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (name: string, description: string, adminUrl?: string, shortname?: string, themeColor?: string, themeBackgroundColor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createProject', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('createProject', 'description', description)
            const localVarPath = `/admin/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (adminUrl !== undefined) {
                localVarQueryParameter['adminUrl'] = adminUrl;
            }

            if (shortname !== undefined) {
                localVarQueryParameter['shortname'] = shortname;
            }

            if (themeColor !== undefined) {
                localVarQueryParameter['themeColor'] = themeColor;
            }

            if (themeBackgroundColor !== undefined) {
                localVarQueryParameter['themeBackgroundColor'] = themeBackgroundColor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (projectId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createRole', 'projectId', projectId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createRole', 'name', name)
            // verify required parameter 'isPublic' is not null or undefined
            assertParamExists('createRole', 'isPublic', isPublic)
            // verify required parameter 'privileges' is not null or undefined
            assertParamExists('createRole', 'privileges', privileges)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('createRole', 'description', description)
            const localVarPath = `/admin/project/{projectId}/role`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['isPublic'] = isPublic;
            }

            if (privileges) {
                localVarQueryParameter['privileges'] = privileges;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecret: async (serviceId: number, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('createSecret', 'serviceId', serviceId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('createSecret', 'key', key)
            const localVarPath = `/admin/service/{serviceId}/secret`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} description 
         * @param {'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI'} type 
         * @param {Array<number>} [dependencies] 
         * @param {Array<number>} [otherDependencies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createService: async (projectId: number, name: string, description: string, type: 'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI', dependencies?: Array<number>, otherDependencies?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createService', 'projectId', projectId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createService', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('createService', 'description', description)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('createService', 'type', type)
            const localVarPath = `/admin/project/{projectId}/service`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (dependencies) {
                localVarQueryParameter['dependencies'] = dependencies;
            }

            if (otherDependencies) {
                localVarQueryParameter['otherDependencies'] = otherDependencies;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags: async (projectId: number, names: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTags', 'projectId', projectId)
            // verify required parameter 'names' is not null or undefined
            assertParamExists('createTags', 'names', names)
            const localVarPath = `/admin/project/{projectId}/tags`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (names) {
                localVarQueryParameter['names'] = names;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVolume: async (serviceId: number, name: string, size: string, mount: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('createVolume', 'serviceId', serviceId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createVolume', 'name', name)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('createVolume', 'size', size)
            // verify required parameter 'mount' is not null or undefined
            assertParamExists('createVolume', 'mount', mount)
            const localVarPath = `/admin/service/{serviceId}/volume`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (mount !== undefined) {
                localVarQueryParameter['mount'] = mount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEnvironment', 'id', id)
            const localVarPath = `/admin/environment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} envId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentVariable: async (envId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'envId' is not null or undefined
            assertParamExists('deleteEnvironmentVariable', 'envId', envId)
            const localVarPath = `/admin/env/{envId}`
                .replace(`{${"envId"}}`, encodeURIComponent(String(envId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameter: async (parameterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parameterId' is not null or undefined
            assertParamExists('deleteParameter', 'parameterId', parameterId)
            const localVarPath = `/admin/parameter/{parameterId}`
                .replace(`{${"parameterId"}}`, encodeURIComponent(String(parameterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('deleteProperty', 'propertyId', propertyId)
            const localVarPath = `/admin/property/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecret: async (secretId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretId' is not null or undefined
            assertParamExists('deleteSecret', 'secretId', secretId)
            const localVarPath = `/admin/secret/{secretId}`
                .replace(`{${"secretId"}}`, encodeURIComponent(String(secretId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteService: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('deleteService', 'serviceId', serviceId)
            const localVarPath = `/admin/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVolume: async (volumeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'volumeId' is not null or undefined
            assertParamExists('deleteVolume', 'volumeId', volumeId)
            const localVarPath = `/admin/volume/{volumeId}`
                .replace(`{${"volumeId"}}`, encodeURIComponent(String(volumeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/company/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDockerServiceProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getDockerServiceProperty', 'propertyId', propertyId)
            const localVarPath = `/admin/dockerproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthcheckServiceProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getHealthcheckServiceProperty', 'propertyId', propertyId)
            const localVarPath = `/admin/healthcheckproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK8sServiceProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getK8sServiceProperty', 'propertyId', propertyId)
            const localVarPath = `/admin/k8sproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2ServiceProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getOAuth2ServiceProperty', 'propertyId', propertyId)
            const localVarPath = `/admin/oauth2property/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPathServiceProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getPathServiceProperty', 'propertyId', propertyId)
            const localVarPath = `/admin/pathproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortMappingServiceProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getPortMappingServiceProperty', 'propertyId', propertyId)
            const localVarPath = `/admin/portmappingproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectMembers: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectMembers', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/members`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPrivileges: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectPrivileges', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/privileges`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRoles: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRoles', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/roles`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectServices: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectServices', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/services`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipe: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getRecipe', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/recipe`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceEnvironmentVariables: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getServiceEnvironmentVariables', 'serviceId', serviceId)
            const localVarPath = `/admin/service/{serviceId}/environmentvariables`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceParameters: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getServiceParameters', 'serviceId', serviceId)
            const localVarPath = `/admin/service/{serviceId}/parameters`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceProperties: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getServiceProperties', 'serviceId', serviceId)
            const localVarPath = `/admin/service/{serviceId}/properties`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceSecrets: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getServiceSecrets', 'serviceId', serviceId)
            const localVarPath = `/admin/service/{serviceId}/secrets`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceVolumes: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getServiceVolumes', 'serviceId', serviceId)
            const localVarPath = `/admin/service/{serviceId}/volumes`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/user/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/user/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} image 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDockerServiceProperty: async (serviceId: number, image: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postDockerServiceProperty', 'serviceId', serviceId)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('postDockerServiceProperty', 'image', image)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('postDockerServiceProperty', 'version', version)
            const localVarPath = `/admin/service/{serviceId}/docker`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (image !== undefined) {
                localVarQueryParameter['image'] = image;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} domain 
         * @param {number} [precedentEnvironment] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnvironment: async (projectId: number, name: string, domain: string, precedentEnvironment?: number, tags?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postEnvironment', 'projectId', projectId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('postEnvironment', 'name', name)
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('postEnvironment', 'domain', domain)
            const localVarPath = `/admin/project/{projectId}/environment`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (precedentEnvironment !== undefined) {
                localVarQueryParameter['precedentEnvironment'] = precedentEnvironment;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} email 
         * @param {string} language 
         * @param {string} registerEmail 
         * @param {string} acceptEmail 
         * @param {string} from 
         * @param {string} registerUrl 
         * @param {string} redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvite: async (projectId: number, email: string, language: string, registerEmail: string, acceptEmail: string, from: string, registerUrl: string, redirectUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postInvite', 'projectId', projectId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('postInvite', 'email', email)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('postInvite', 'language', language)
            // verify required parameter 'registerEmail' is not null or undefined
            assertParamExists('postInvite', 'registerEmail', registerEmail)
            // verify required parameter 'acceptEmail' is not null or undefined
            assertParamExists('postInvite', 'acceptEmail', acceptEmail)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('postInvite', 'from', from)
            // verify required parameter 'registerUrl' is not null or undefined
            assertParamExists('postInvite', 'registerUrl', registerUrl)
            // verify required parameter 'redirectUrl' is not null or undefined
            assertParamExists('postInvite', 'redirectUrl', redirectUrl)
            const localVarPath = `/admin/project/{projectId}/invite`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (registerEmail !== undefined) {
                localVarQueryParameter['registerEmail'] = registerEmail;
            }

            if (acceptEmail !== undefined) {
                localVarQueryParameter['acceptEmail'] = acceptEmail;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (registerUrl !== undefined) {
                localVarQueryParameter['registerUrl'] = registerUrl;
            }

            if (redirectUrl !== undefined) {
                localVarQueryParameter['redirectUrl'] = redirectUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} replicaCount 
         * @param {number} cpuLimit 
         * @param {number} memoryLimit 
         * @param {number} cpuRequest 
         * @param {number} memoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postK8sServiceProperty: async (serviceId: number, replicaCount: number, cpuLimit: number, memoryLimit: number, cpuRequest: number, memoryRequest: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postK8sServiceProperty', 'serviceId', serviceId)
            // verify required parameter 'replicaCount' is not null or undefined
            assertParamExists('postK8sServiceProperty', 'replicaCount', replicaCount)
            // verify required parameter 'cpuLimit' is not null or undefined
            assertParamExists('postK8sServiceProperty', 'cpuLimit', cpuLimit)
            // verify required parameter 'memoryLimit' is not null or undefined
            assertParamExists('postK8sServiceProperty', 'memoryLimit', memoryLimit)
            // verify required parameter 'cpuRequest' is not null or undefined
            assertParamExists('postK8sServiceProperty', 'cpuRequest', cpuRequest)
            // verify required parameter 'memoryRequest' is not null or undefined
            assertParamExists('postK8sServiceProperty', 'memoryRequest', memoryRequest)
            const localVarPath = `/admin/service/{serviceId}/k8s`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (replicaCount !== undefined) {
                localVarQueryParameter['replicaCount'] = replicaCount;
            }

            if (cpuLimit !== undefined) {
                localVarQueryParameter['cpuLimit'] = cpuLimit;
            }

            if (memoryLimit !== undefined) {
                localVarQueryParameter['memoryLimit'] = memoryLimit;
            }

            if (cpuRequest !== undefined) {
                localVarQueryParameter['cpuRequest'] = cpuRequest;
            }

            if (memoryRequest !== undefined) {
                localVarQueryParameter['memoryRequest'] = memoryRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOAuth2ComponentServiceProperty: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postOAuth2ComponentServiceProperty', 'serviceId', serviceId)
            const localVarPath = `/admin/service/{serviceId}/oauth2component`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} clientId 
         * @param {string} clientPassword 
         * @param {string} clientScope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOAuth2ServiceProperty: async (serviceId: number, clientId: string, clientPassword: string, clientScope: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postOAuth2ServiceProperty', 'serviceId', serviceId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('postOAuth2ServiceProperty', 'clientId', clientId)
            // verify required parameter 'clientPassword' is not null or undefined
            assertParamExists('postOAuth2ServiceProperty', 'clientPassword', clientPassword)
            // verify required parameter 'clientScope' is not null or undefined
            assertParamExists('postOAuth2ServiceProperty', 'clientScope', clientScope)
            const localVarPath = `/admin/service/{serviceId}/oauth2`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (clientPassword !== undefined) {
                localVarQueryParameter['clientPassword'] = clientPassword;
            }

            if (clientScope !== undefined) {
                localVarQueryParameter['clientScope'] = clientScope;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} port 
         * @param {string} path 
         * @param {string} host 
         * @param {boolean} redirectWWW 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPathServiceProperty: async (serviceId: number, port: number, path: string, host: string, redirectWWW: boolean, basicAuthUser?: string, basicAuthPassword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postPathServiceProperty', 'serviceId', serviceId)
            // verify required parameter 'port' is not null or undefined
            assertParamExists('postPathServiceProperty', 'port', port)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('postPathServiceProperty', 'path', path)
            // verify required parameter 'host' is not null or undefined
            assertParamExists('postPathServiceProperty', 'host', host)
            // verify required parameter 'redirectWWW' is not null or undefined
            assertParamExists('postPathServiceProperty', 'redirectWWW', redirectWWW)
            const localVarPath = `/admin/service/{serviceId}/path`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (port !== undefined) {
                localVarQueryParameter['port'] = port;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (redirectWWW !== undefined) {
                localVarQueryParameter['redirectWWW'] = redirectWWW;
            }

            if (basicAuthUser !== undefined) {
                localVarQueryParameter['basicAuthUser'] = basicAuthUser;
            }

            if (basicAuthPassword !== undefined) {
                localVarQueryParameter['basicAuthPassword'] = basicAuthPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortMappingServiceProperty: async (serviceId: number, port: number, containerPort: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postPortMappingServiceProperty', 'serviceId', serviceId)
            // verify required parameter 'port' is not null or undefined
            assertParamExists('postPortMappingServiceProperty', 'port', port)
            // verify required parameter 'containerPort' is not null or undefined
            assertParamExists('postPortMappingServiceProperty', 'containerPort', containerPort)
            const localVarPath = `/admin/service/{serviceId}/portmapping`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (port !== undefined) {
                localVarQueryParameter['port'] = port;
            }

            if (containerPort !== undefined) {
                localVarQueryParameter['containerPort'] = containerPort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} major 
         * @param {number} minor 
         * @param {number} patch 
         * @param {string} [cause] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRelease: async (id: number, major: number, minor: number, patch: number, cause?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postRelease', 'id', id)
            // verify required parameter 'major' is not null or undefined
            assertParamExists('postRelease', 'major', major)
            // verify required parameter 'minor' is not null or undefined
            assertParamExists('postRelease', 'minor', minor)
            // verify required parameter 'patch' is not null or undefined
            assertParamExists('postRelease', 'patch', patch)
            const localVarPath = `/admin/environment/{id}/release`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (major !== undefined) {
                localVarQueryParameter['major'] = major;
            }

            if (minor !== undefined) {
                localVarQueryParameter['minor'] = minor;
            }

            if (patch !== undefined) {
                localVarQueryParameter['patch'] = patch;
            }

            if (cause !== undefined) {
                localVarQueryParameter['cause'] = cause;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postServiceHealthcheck: async (serviceId: number, url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postServiceHealthcheck', 'serviceId', serviceId)
            // verify required parameter 'url' is not null or undefined
            assertParamExists('postServiceHealthcheck', 'url', url)
            const localVarPath = `/admin/service/{serviceId}/healthcheck`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} environmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoteRelease: async (id: number, environmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promoteRelease', 'id', id)
            // verify required parameter 'environmentId' is not null or undefined
            assertParamExists('promoteRelease', 'environmentId', environmentId)
            const localVarPath = `/admin/release/{id}/release`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyName 
         * @param {string} firstname 
         * @param {string} lastname 
         * @param {'SOFTWARE_ENGINEER' | 'FREELANCE' | 'ENTREPRENEUR' | 'ARCHITECT' | 'CHIEF_TECHNOLOGY_OFFICER' | 'OTHER'} title 
         * @param {number} [companyPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putActivateUser: async (companyName: string, firstname: string, lastname: string, title: 'SOFTWARE_ENGINEER' | 'FREELANCE' | 'ENTREPRENEUR' | 'ARCHITECT' | 'CHIEF_TECHNOLOGY_OFFICER' | 'OTHER', companyPhone?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyName' is not null or undefined
            assertParamExists('putActivateUser', 'companyName', companyName)
            // verify required parameter 'firstname' is not null or undefined
            assertParamExists('putActivateUser', 'firstname', firstname)
            // verify required parameter 'lastname' is not null or undefined
            assertParamExists('putActivateUser', 'lastname', lastname)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('putActivateUser', 'title', title)
            const localVarPath = `/admin/user/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (companyPhone !== undefined) {
                localVarQueryParameter['companyPhone'] = companyPhone;
            }

            if (firstname !== undefined) {
                localVarQueryParameter['firstname'] = firstname;
            }

            if (lastname !== undefined) {
                localVarQueryParameter['lastname'] = lastname;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} image 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDockerServiceProperty: async (propertyId: number, image: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('putDockerServiceProperty', 'propertyId', propertyId)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('putDockerServiceProperty', 'image', image)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('putDockerServiceProperty', 'version', version)
            const localVarPath = `/admin/dockerproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (image !== undefined) {
                localVarQueryParameter['image'] = image;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} configId 
         * @param {string} name 
         * @param {string} domain 
         * @param {number} [precedentEnvironment] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEnvironment: async (configId: number, name: string, domain: string, precedentEnvironment?: number, tags?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('putEnvironment', 'configId', configId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('putEnvironment', 'name', name)
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('putEnvironment', 'domain', domain)
            const localVarPath = `/admin/environment/{configId}`
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (precedentEnvironment !== undefined) {
                localVarQueryParameter['precedentEnvironment'] = precedentEnvironment;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} username 
         * @param {string} repository 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGit: async (projectId: number, username: string, repository: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('putGit', 'projectId', projectId)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('putGit', 'username', username)
            // verify required parameter 'repository' is not null or undefined
            assertParamExists('putGit', 'repository', repository)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('putGit', 'password', password)
            const localVarPath = `/admin/project/{projectId}/git`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (repository !== undefined) {
                localVarQueryParameter['repository'] = repository;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHealthcheckServiceProperty: async (propertyId: number, url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('putHealthcheckServiceProperty', 'propertyId', propertyId)
            // verify required parameter 'url' is not null or undefined
            assertParamExists('putHealthcheckServiceProperty', 'url', url)
            const localVarPath = `/admin/healthcheckproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} replicaCount 
         * @param {number} cpuLimit 
         * @param {number} memoryLimit 
         * @param {number} cpuRequest 
         * @param {number} memoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putK8sServiceProperty: async (propertyId: number, replicaCount: number, cpuLimit: number, memoryLimit: number, cpuRequest: number, memoryRequest: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('putK8sServiceProperty', 'propertyId', propertyId)
            // verify required parameter 'replicaCount' is not null or undefined
            assertParamExists('putK8sServiceProperty', 'replicaCount', replicaCount)
            // verify required parameter 'cpuLimit' is not null or undefined
            assertParamExists('putK8sServiceProperty', 'cpuLimit', cpuLimit)
            // verify required parameter 'memoryLimit' is not null or undefined
            assertParamExists('putK8sServiceProperty', 'memoryLimit', memoryLimit)
            // verify required parameter 'cpuRequest' is not null or undefined
            assertParamExists('putK8sServiceProperty', 'cpuRequest', cpuRequest)
            // verify required parameter 'memoryRequest' is not null or undefined
            assertParamExists('putK8sServiceProperty', 'memoryRequest', memoryRequest)
            const localVarPath = `/admin/k8sproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (replicaCount !== undefined) {
                localVarQueryParameter['replicaCount'] = replicaCount;
            }

            if (cpuLimit !== undefined) {
                localVarQueryParameter['cpuLimit'] = cpuLimit;
            }

            if (memoryLimit !== undefined) {
                localVarQueryParameter['memoryLimit'] = memoryLimit;
            }

            if (cpuRequest !== undefined) {
                localVarQueryParameter['cpuRequest'] = cpuRequest;
            }

            if (memoryRequest !== undefined) {
                localVarQueryParameter['memoryRequest'] = memoryRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} clientId 
         * @param {string} clientPassword 
         * @param {string} clientScope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOAuth2ServiceProperty: async (propertyId: number, clientId: string, clientPassword: string, clientScope: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('putOAuth2ServiceProperty', 'propertyId', propertyId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('putOAuth2ServiceProperty', 'clientId', clientId)
            // verify required parameter 'clientPassword' is not null or undefined
            assertParamExists('putOAuth2ServiceProperty', 'clientPassword', clientPassword)
            // verify required parameter 'clientScope' is not null or undefined
            assertParamExists('putOAuth2ServiceProperty', 'clientScope', clientScope)
            const localVarPath = `/admin/oauth2property/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (clientPassword !== undefined) {
                localVarQueryParameter['clientPassword'] = clientPassword;
            }

            if (clientScope !== undefined) {
                localVarQueryParameter['clientScope'] = clientScope;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} port 
         * @param {string} path 
         * @param {string} host 
         * @param {boolean} redirectWWW 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPathServiceProperty: async (propertyId: number, port: number, path: string, host: string, redirectWWW: boolean, basicAuthUser?: string, basicAuthPassword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('putPathServiceProperty', 'propertyId', propertyId)
            // verify required parameter 'port' is not null or undefined
            assertParamExists('putPathServiceProperty', 'port', port)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('putPathServiceProperty', 'path', path)
            // verify required parameter 'host' is not null or undefined
            assertParamExists('putPathServiceProperty', 'host', host)
            // verify required parameter 'redirectWWW' is not null or undefined
            assertParamExists('putPathServiceProperty', 'redirectWWW', redirectWWW)
            const localVarPath = `/admin/pathproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (port !== undefined) {
                localVarQueryParameter['port'] = port;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (redirectWWW !== undefined) {
                localVarQueryParameter['redirectWWW'] = redirectWWW;
            }

            if (basicAuthUser !== undefined) {
                localVarQueryParameter['basicAuthUser'] = basicAuthUser;
            }

            if (basicAuthPassword !== undefined) {
                localVarQueryParameter['basicAuthPassword'] = basicAuthPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPortMappingServiceProperty: async (propertyId: number, port: number, containerPort: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('putPortMappingServiceProperty', 'propertyId', propertyId)
            // verify required parameter 'port' is not null or undefined
            assertParamExists('putPortMappingServiceProperty', 'port', port)
            // verify required parameter 'containerPort' is not null or undefined
            assertParamExists('putPortMappingServiceProperty', 'containerPort', containerPort)
            const localVarPath = `/admin/portmappingproperty/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (port !== undefined) {
                localVarQueryParameter['port'] = port;
            }

            if (containerPort !== undefined) {
                localVarQueryParameter['containerPort'] = containerPort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} privilegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRolePrivilege: async (roleId: number, privilegeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('setRolePrivilege', 'roleId', roleId)
            // verify required parameter 'privilegeId' is not null or undefined
            assertParamExists('setRolePrivilege', 'privilegeId', privilegeId)
            const localVarPath = `/admin/role/{roleId}/privilege`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (privilegeId !== undefined) {
                localVarQueryParameter['privilegeId'] = privilegeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} envId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {boolean} [isExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvironmentVariable: async (envId: number, key: string, value?: string, secretId?: number, parameterId?: number, isExpression?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'envId' is not null or undefined
            assertParamExists('updateEnvironmentVariable', 'envId', envId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateEnvironmentVariable', 'key', key)
            const localVarPath = `/admin/env/{envId}`
                .replace(`{${"envId"}}`, encodeURIComponent(String(envId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (secretId !== undefined) {
                localVarQueryParameter['secret_id'] = secretId;
            }

            if (parameterId !== undefined) {
                localVarQueryParameter['parameter_id'] = parameterId;
            }

            if (isExpression !== undefined) {
                localVarQueryParameter['isExpression'] = isExpression;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParameter: async (parameterId: number, key: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parameterId' is not null or undefined
            assertParamExists('updateParameter', 'parameterId', parameterId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateParameter', 'key', key)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('updateParameter', 'value', value)
            const localVarPath = `/admin/parameter/{parameterId}`
                .replace(`{${"parameterId"}}`, encodeURIComponent(String(parameterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} description 
         * @param {string} [adminUrl] 
         * @param {string} [shortname] 
         * @param {string} [themeColor] 
         * @param {string} [themeBackgroundColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: number, description: string, adminUrl?: string, shortname?: string, themeColor?: string, themeBackgroundColor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('updateProject', 'description', description)
            const localVarPath = `/admin/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (adminUrl !== undefined) {
                localVarQueryParameter['adminUrl'] = adminUrl;
            }

            if (shortname !== undefined) {
                localVarQueryParameter['shortname'] = shortname;
            }

            if (themeColor !== undefined) {
                localVarQueryParameter['themeColor'] = themeColor;
            }

            if (themeBackgroundColor !== undefined) {
                localVarQueryParameter['themeBackgroundColor'] = themeBackgroundColor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (roleId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('updateRole', 'roleId', roleId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateRole', 'name', name)
            // verify required parameter 'isPublic' is not null or undefined
            assertParamExists('updateRole', 'isPublic', isPublic)
            // verify required parameter 'privileges' is not null or undefined
            assertParamExists('updateRole', 'privileges', privileges)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('updateRole', 'description', description)
            const localVarPath = `/admin/role/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['isPublic'] = isPublic;
            }

            if (privileges) {
                localVarQueryParameter['privileges'] = privileges;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} secretId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSecret: async (secretId: number, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretId' is not null or undefined
            assertParamExists('updateSecret', 'secretId', secretId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateSecret', 'key', key)
            const localVarPath = `/admin/secret/{secretId}`
                .replace(`{${"secretId"}}`, encodeURIComponent(String(secretId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} description 
         * @param {'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI'} type 
         * @param {Array<number>} [dependencies] 
         * @param {Array<number>} [otherDependencies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateService: async (serviceId: number, name: string, description: string, type: 'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI', dependencies?: Array<number>, otherDependencies?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('updateService', 'serviceId', serviceId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateService', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('updateService', 'description', description)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('updateService', 'type', type)
            const localVarPath = `/admin/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (dependencies) {
                localVarQueryParameter['dependencies'] = dependencies;
            }

            if (otherDependencies) {
                localVarQueryParameter['otherDependencies'] = otherDependencies;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVolume: async (volumeId: number, name: string, size: string, mount: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'volumeId' is not null or undefined
            assertParamExists('updateVolume', 'volumeId', volumeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateVolume', 'name', name)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('updateVolume', 'size', size)
            // verify required parameter 'mount' is not null or undefined
            assertParamExists('updateVolume', 'mount', mount)
            const localVarPath = `/admin/volume/{volumeId}`
                .replace(`{${"volumeId"}}`, encodeURIComponent(String(volumeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (mount !== undefined) {
                localVarQueryParameter['mount'] = mount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAppletouchIcon: async (projectId: number, inlineObject4?: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadAppletouchIcon', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/appletouchicon`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFavIcon: async (projectId: number, inlineObject3?: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadFavIcon', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/favicon`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIcon: async (projectId: number, inlineObject2?: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadIcon', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/icon`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo: async (projectId: number, inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadLogo', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/logo`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMaskableIcon: async (projectId: number, inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadMaskableIcon', 'projectId', projectId)
            const localVarPath = `/admin/project/{projectId}/maskableicon`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {boolean} [isExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEnvironmentVariable(serviceId: number, key: string, value?: string, secretId?: number, parameterId?: number, isExpression?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentVariableResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEnvironmentVariable(serviceId, key, value, secretId, parameterId, isExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParameter(serviceId: number, key: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParameter(serviceId, key, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPrivileges(projectId: number, names: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrivilegeResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrivileges(projectId, names, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [adminUrl] 
         * @param {string} [shortname] 
         * @param {string} [themeColor] 
         * @param {string} [themeBackgroundColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(name: string, description: string, adminUrl?: string, shortname?: string, themeColor?: string, themeBackgroundColor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(name, description, adminUrl, shortname, themeColor, themeBackgroundColor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(projectId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(projectId, name, isPublic, privileges, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSecret(serviceId: number, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSecret(serviceId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} description 
         * @param {'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI'} type 
         * @param {Array<number>} [dependencies] 
         * @param {Array<number>} [otherDependencies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createService(projectId: number, name: string, description: string, type: 'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI', dependencies?: Array<number>, otherDependencies?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createService(projectId, name, description, type, dependencies, otherDependencies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTags(projectId: number, names: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTags(projectId, names, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVolume(serviceId: number, name: string, size: string, mount: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolumeResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVolume(serviceId, name, size, mount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnvironment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnvironment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} envId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnvironmentVariable(envId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnvironmentVariable(envId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParameter(parameterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParameter(parameterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSecret(secretId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSecret(secretId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteService(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteService(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVolume(volumeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVolume(volumeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDockerServiceProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DockerServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDockerServiceProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthcheckServiceProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthcheckServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthcheckServiceProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getK8sServiceProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<K8sServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getK8sServiceProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOAuth2ServiceProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuth2ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOAuth2ServiceProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPathServiceProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PathServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPathServiceProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortMappingServiceProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortMappingServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortMappingServiceProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectMembers(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectMembers(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectPrivileges(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrivilegeResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectPrivileges(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRoles(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRoles(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectServices(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectServices(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipe(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipe(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceEnvironmentVariables(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvironmentVariableResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceEnvironmentVariables(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceParameters(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParameterResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceParameters(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceProperties(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServicePropertyResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceProperties(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceSecrets(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecretResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceSecrets(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceVolumes(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VolumeResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceVolumes(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} image 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDockerServiceProperty(serviceId: number, image: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDockerServiceProperty(serviceId, image, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} domain 
         * @param {number} [precedentEnvironment] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEnvironment(projectId: number, name: string, domain: string, precedentEnvironment?: number, tags?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEnvironment(projectId, name, domain, precedentEnvironment, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} email 
         * @param {string} language 
         * @param {string} registerEmail 
         * @param {string} acceptEmail 
         * @param {string} from 
         * @param {string} registerUrl 
         * @param {string} redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInvite(projectId: number, email: string, language: string, registerEmail: string, acceptEmail: string, from: string, registerUrl: string, redirectUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInvite(projectId, email, language, registerEmail, acceptEmail, from, registerUrl, redirectUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} replicaCount 
         * @param {number} cpuLimit 
         * @param {number} memoryLimit 
         * @param {number} cpuRequest 
         * @param {number} memoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postK8sServiceProperty(serviceId: number, replicaCount: number, cpuLimit: number, memoryLimit: number, cpuRequest: number, memoryRequest: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postK8sServiceProperty(serviceId, replicaCount, cpuLimit, memoryLimit, cpuRequest, memoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOAuth2ComponentServiceProperty(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOAuth2ComponentServiceProperty(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} clientId 
         * @param {string} clientPassword 
         * @param {string} clientScope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOAuth2ServiceProperty(serviceId: number, clientId: string, clientPassword: string, clientScope: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOAuth2ServiceProperty(serviceId, clientId, clientPassword, clientScope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} port 
         * @param {string} path 
         * @param {string} host 
         * @param {boolean} redirectWWW 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPathServiceProperty(serviceId: number, port: number, path: string, host: string, redirectWWW: boolean, basicAuthUser?: string, basicAuthPassword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPathServiceProperty(serviceId, port, path, host, redirectWWW, basicAuthUser, basicAuthPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPortMappingServiceProperty(serviceId: number, port: number, containerPort: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPortMappingServiceProperty(serviceId, port, containerPort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} major 
         * @param {number} minor 
         * @param {number} patch 
         * @param {string} [cause] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRelease(id: number, major: number, minor: number, patch: number, cause?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRelease(id, major, minor, patch, cause, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postServiceHealthcheck(serviceId: number, url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postServiceHealthcheck(serviceId, url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} environmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoteRelease(id: number, environmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoteRelease(id, environmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyName 
         * @param {string} firstname 
         * @param {string} lastname 
         * @param {'SOFTWARE_ENGINEER' | 'FREELANCE' | 'ENTREPRENEUR' | 'ARCHITECT' | 'CHIEF_TECHNOLOGY_OFFICER' | 'OTHER'} title 
         * @param {number} [companyPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putActivateUser(companyName: string, firstname: string, lastname: string, title: 'SOFTWARE_ENGINEER' | 'FREELANCE' | 'ENTREPRENEUR' | 'ARCHITECT' | 'CHIEF_TECHNOLOGY_OFFICER' | 'OTHER', companyPhone?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putActivateUser(companyName, firstname, lastname, title, companyPhone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} image 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDockerServiceProperty(propertyId: number, image: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDockerServiceProperty(propertyId, image, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} configId 
         * @param {string} name 
         * @param {string} domain 
         * @param {number} [precedentEnvironment] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEnvironment(configId: number, name: string, domain: string, precedentEnvironment?: number, tags?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEnvironment(configId, name, domain, precedentEnvironment, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} username 
         * @param {string} repository 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGit(projectId: number, username: string, repository: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGit(projectId, username, repository, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putHealthcheckServiceProperty(propertyId: number, url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putHealthcheckServiceProperty(propertyId, url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} replicaCount 
         * @param {number} cpuLimit 
         * @param {number} memoryLimit 
         * @param {number} cpuRequest 
         * @param {number} memoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putK8sServiceProperty(propertyId: number, replicaCount: number, cpuLimit: number, memoryLimit: number, cpuRequest: number, memoryRequest: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putK8sServiceProperty(propertyId, replicaCount, cpuLimit, memoryLimit, cpuRequest, memoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} clientId 
         * @param {string} clientPassword 
         * @param {string} clientScope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOAuth2ServiceProperty(propertyId: number, clientId: string, clientPassword: string, clientScope: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOAuth2ServiceProperty(propertyId, clientId, clientPassword, clientScope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} port 
         * @param {string} path 
         * @param {string} host 
         * @param {boolean} redirectWWW 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPathServiceProperty(propertyId: number, port: number, path: string, host: string, redirectWWW: boolean, basicAuthUser?: string, basicAuthPassword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPathServiceProperty(propertyId, port, path, host, redirectWWW, basicAuthUser, basicAuthPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPortMappingServiceProperty(propertyId: number, port: number, containerPort: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePropertyResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPortMappingServiceProperty(propertyId, port, containerPort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} privilegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRolePrivilege(roleId: number, privilegeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRolePrivilege(roleId, privilegeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} envId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {boolean} [isExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnvironmentVariable(envId: number, key: string, value?: string, secretId?: number, parameterId?: number, isExpression?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentVariableResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnvironmentVariable(envId, key, value, secretId, parameterId, isExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParameter(parameterId: number, key: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParameter(parameterId, key, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} description 
         * @param {string} [adminUrl] 
         * @param {string} [shortname] 
         * @param {string} [themeColor] 
         * @param {string} [themeBackgroundColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: number, description: string, adminUrl?: string, shortname?: string, themeColor?: string, themeBackgroundColor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectId, description, adminUrl, shortname, themeColor, themeBackgroundColor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(roleId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(roleId, name, isPublic, privileges, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} secretId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSecret(secretId: number, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSecret(secretId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} description 
         * @param {'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI'} type 
         * @param {Array<number>} [dependencies] 
         * @param {Array<number>} [otherDependencies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateService(serviceId: number, name: string, description: string, type: 'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI', dependencies?: Array<number>, otherDependencies?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateService(serviceId, name, description, type, dependencies, otherDependencies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVolume(volumeId: number, name: string, size: string, mount: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolumeResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVolume(volumeId, name, size, mount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAppletouchIcon(projectId: number, inlineObject4?: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAppletouchIcon(projectId, inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFavIcon(projectId: number, inlineObject3?: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFavIcon(projectId, inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadIcon(projectId: number, inlineObject2?: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadIcon(projectId, inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLogo(projectId: number, inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLogo(projectId, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMaskableIcon(projectId: number, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMaskableIcon(projectId, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async user(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.user(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {boolean} [isExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironmentVariable(serviceId: number, key: string, value?: string, secretId?: number, parameterId?: number, isExpression?: boolean, options?: any): AxiosPromise<EnvironmentVariableResource> {
            return localVarFp.createEnvironmentVariable(serviceId, key, value, secretId, parameterId, isExpression, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParameter(serviceId: number, key: string, value: string, options?: any): AxiosPromise<ParameterResource> {
            return localVarFp.createParameter(serviceId, key, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrivileges(projectId: number, names: Array<string>, options?: any): AxiosPromise<Array<PrivilegeResource>> {
            return localVarFp.createPrivileges(projectId, names, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} [adminUrl] 
         * @param {string} [shortname] 
         * @param {string} [themeColor] 
         * @param {string} [themeBackgroundColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(name: string, description: string, adminUrl?: string, shortname?: string, themeColor?: string, themeBackgroundColor?: string, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.createProject(name, description, adminUrl, shortname, themeColor, themeBackgroundColor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(projectId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: any): AxiosPromise<RoleResource> {
            return localVarFp.createRole(projectId, name, isPublic, privileges, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecret(serviceId: number, key: string, options?: any): AxiosPromise<SecretResource> {
            return localVarFp.createSecret(serviceId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} description 
         * @param {'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI'} type 
         * @param {Array<number>} [dependencies] 
         * @param {Array<number>} [otherDependencies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createService(projectId: number, name: string, description: string, type: 'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI', dependencies?: Array<number>, otherDependencies?: Array<number>, options?: any): AxiosPromise<ServiceResource> {
            return localVarFp.createService(projectId, name, description, type, dependencies, otherDependencies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags(projectId: number, names: Array<string>, options?: any): AxiosPromise<Array<TagResource>> {
            return localVarFp.createTags(projectId, names, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVolume(serviceId: number, name: string, size: string, mount: string, options?: any): AxiosPromise<VolumeResource> {
            return localVarFp.createVolume(serviceId, name, size, mount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironment(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEnvironment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} envId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentVariable(envId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEnvironmentVariable(envId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameter(parameterId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteParameter(parameterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProperty(propertyId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecret(secretId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteSecret(secretId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteService(serviceId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteService(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVolume(volumeId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteVolume(volumeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProjects(options?: any): AxiosPromise<Array<ProjectResource>> {
            return localVarFp.getCompanyProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDockerServiceProperty(propertyId: number, options?: any): AxiosPromise<DockerServicePropertyResource> {
            return localVarFp.getDockerServiceProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthcheckServiceProperty(propertyId: number, options?: any): AxiosPromise<HealthcheckServicePropertyResource> {
            return localVarFp.getHealthcheckServiceProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK8sServiceProperty(propertyId: number, options?: any): AxiosPromise<K8sServicePropertyResource> {
            return localVarFp.getK8sServiceProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2ServiceProperty(propertyId: number, options?: any): AxiosPromise<OAuth2ServicePropertyResource> {
            return localVarFp.getOAuth2ServiceProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPathServiceProperty(propertyId: number, options?: any): AxiosPromise<PathServicePropertyResource> {
            return localVarFp.getPathServiceProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortMappingServiceProperty(propertyId: number, options?: any): AxiosPromise<PortMappingServicePropertyResource> {
            return localVarFp.getPortMappingServiceProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectMembers(projectId: number, options?: any): AxiosPromise<Array<IdentityResource>> {
            return localVarFp.getProjectMembers(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPrivileges(projectId: number, options?: any): AxiosPromise<Array<PrivilegeResource>> {
            return localVarFp.getProjectPrivileges(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRoles(projectId: number, options?: any): AxiosPromise<Array<RoleResource>> {
            return localVarFp.getProjectRoles(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectServices(projectId: number, options?: any): AxiosPromise<Array<ServiceResource>> {
            return localVarFp.getProjectServices(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipe(projectId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getRecipe(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceEnvironmentVariables(serviceId: number, options?: any): AxiosPromise<Array<EnvironmentVariableResource>> {
            return localVarFp.getServiceEnvironmentVariables(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceParameters(serviceId: number, options?: any): AxiosPromise<Array<ParameterResource>> {
            return localVarFp.getServiceParameters(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceProperties(serviceId: number, options?: any): AxiosPromise<Array<ServicePropertyResource>> {
            return localVarFp.getServiceProperties(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceSecrets(serviceId: number, options?: any): AxiosPromise<Array<SecretResource>> {
            return localVarFp.getServiceSecrets(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceVolumes(serviceId: number, options?: any): AxiosPromise<Array<VolumeResource>> {
            return localVarFp.getServiceVolumes(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompany(options?: any): AxiosPromise<CompanyResource> {
            return localVarFp.getUserCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjects(options?: any): AxiosPromise<Array<ProjectResource>> {
            return localVarFp.getUserProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} image 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDockerServiceProperty(serviceId: number, image: string, version: string, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.postDockerServiceProperty(serviceId, image, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} domain 
         * @param {number} [precedentEnvironment] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnvironment(projectId: number, name: string, domain: string, precedentEnvironment?: number, tags?: Array<number>, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.postEnvironment(projectId, name, domain, precedentEnvironment, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} email 
         * @param {string} language 
         * @param {string} registerEmail 
         * @param {string} acceptEmail 
         * @param {string} from 
         * @param {string} registerUrl 
         * @param {string} redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvite(projectId: number, email: string, language: string, registerEmail: string, acceptEmail: string, from: string, registerUrl: string, redirectUrl: string, options?: any): AxiosPromise<InviteResource> {
            return localVarFp.postInvite(projectId, email, language, registerEmail, acceptEmail, from, registerUrl, redirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} replicaCount 
         * @param {number} cpuLimit 
         * @param {number} memoryLimit 
         * @param {number} cpuRequest 
         * @param {number} memoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postK8sServiceProperty(serviceId: number, replicaCount: number, cpuLimit: number, memoryLimit: number, cpuRequest: number, memoryRequest: number, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.postK8sServiceProperty(serviceId, replicaCount, cpuLimit, memoryLimit, cpuRequest, memoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOAuth2ComponentServiceProperty(serviceId: number, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.postOAuth2ComponentServiceProperty(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} clientId 
         * @param {string} clientPassword 
         * @param {string} clientScope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOAuth2ServiceProperty(serviceId: number, clientId: string, clientPassword: string, clientScope: string, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.postOAuth2ServiceProperty(serviceId, clientId, clientPassword, clientScope, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} port 
         * @param {string} path 
         * @param {string} host 
         * @param {boolean} redirectWWW 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPathServiceProperty(serviceId: number, port: number, path: string, host: string, redirectWWW: boolean, basicAuthUser?: string, basicAuthPassword?: string, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.postPathServiceProperty(serviceId, port, path, host, redirectWWW, basicAuthUser, basicAuthPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortMappingServiceProperty(serviceId: number, port: number, containerPort: number, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.postPortMappingServiceProperty(serviceId, port, containerPort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} major 
         * @param {number} minor 
         * @param {number} patch 
         * @param {string} [cause] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRelease(id: number, major: number, minor: number, patch: number, cause?: string, options?: any): AxiosPromise<ReleaseResource> {
            return localVarFp.postRelease(id, major, minor, patch, cause, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postServiceHealthcheck(serviceId: number, url: string, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.postServiceHealthcheck(serviceId, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(options?: any): AxiosPromise<UserResource> {
            return localVarFp.postUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} environmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoteRelease(id: number, environmentId: number, options?: any): AxiosPromise<ReleaseResource> {
            return localVarFp.promoteRelease(id, environmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyName 
         * @param {string} firstname 
         * @param {string} lastname 
         * @param {'SOFTWARE_ENGINEER' | 'FREELANCE' | 'ENTREPRENEUR' | 'ARCHITECT' | 'CHIEF_TECHNOLOGY_OFFICER' | 'OTHER'} title 
         * @param {number} [companyPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putActivateUser(companyName: string, firstname: string, lastname: string, title: 'SOFTWARE_ENGINEER' | 'FREELANCE' | 'ENTREPRENEUR' | 'ARCHITECT' | 'CHIEF_TECHNOLOGY_OFFICER' | 'OTHER', companyPhone?: number, options?: any): AxiosPromise<UserResource> {
            return localVarFp.putActivateUser(companyName, firstname, lastname, title, companyPhone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} image 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDockerServiceProperty(propertyId: number, image: string, version: string, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.putDockerServiceProperty(propertyId, image, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} configId 
         * @param {string} name 
         * @param {string} domain 
         * @param {number} [precedentEnvironment] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEnvironment(configId: number, name: string, domain: string, precedentEnvironment?: number, tags?: Array<number>, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.putEnvironment(configId, name, domain, precedentEnvironment, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} username 
         * @param {string} repository 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGit(projectId: number, username: string, repository: string, password: string, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.putGit(projectId, username, repository, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHealthcheckServiceProperty(propertyId: number, url: string, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.putHealthcheckServiceProperty(propertyId, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} replicaCount 
         * @param {number} cpuLimit 
         * @param {number} memoryLimit 
         * @param {number} cpuRequest 
         * @param {number} memoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putK8sServiceProperty(propertyId: number, replicaCount: number, cpuLimit: number, memoryLimit: number, cpuRequest: number, memoryRequest: number, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.putK8sServiceProperty(propertyId, replicaCount, cpuLimit, memoryLimit, cpuRequest, memoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {string} clientId 
         * @param {string} clientPassword 
         * @param {string} clientScope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOAuth2ServiceProperty(propertyId: number, clientId: string, clientPassword: string, clientScope: string, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.putOAuth2ServiceProperty(propertyId, clientId, clientPassword, clientScope, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} port 
         * @param {string} path 
         * @param {string} host 
         * @param {boolean} redirectWWW 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPathServiceProperty(propertyId: number, port: number, path: string, host: string, redirectWWW: boolean, basicAuthUser?: string, basicAuthPassword?: string, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.putPathServiceProperty(propertyId, port, path, host, redirectWWW, basicAuthUser, basicAuthPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPortMappingServiceProperty(propertyId: number, port: number, containerPort: number, options?: any): AxiosPromise<ServicePropertyResource> {
            return localVarFp.putPortMappingServiceProperty(propertyId, port, containerPort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} privilegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRolePrivilege(roleId: number, privilegeId: number, options?: any): AxiosPromise<RoleResource> {
            return localVarFp.setRolePrivilege(roleId, privilegeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} envId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {boolean} [isExpression] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvironmentVariable(envId: number, key: string, value?: string, secretId?: number, parameterId?: number, isExpression?: boolean, options?: any): AxiosPromise<EnvironmentVariableResource> {
            return localVarFp.updateEnvironmentVariable(envId, key, value, secretId, parameterId, isExpression, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParameter(parameterId: number, key: string, value: string, options?: any): AxiosPromise<ParameterResource> {
            return localVarFp.updateParameter(parameterId, key, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} description 
         * @param {string} [adminUrl] 
         * @param {string} [shortname] 
         * @param {string} [themeColor] 
         * @param {string} [themeBackgroundColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: number, description: string, adminUrl?: string, shortname?: string, themeColor?: string, themeBackgroundColor?: string, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.updateProject(projectId, description, adminUrl, shortname, themeColor, themeBackgroundColor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(roleId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: any): AxiosPromise<RoleResource> {
            return localVarFp.updateRole(roleId, name, isPublic, privileges, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} secretId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSecret(secretId: number, key: string, options?: any): AxiosPromise<SecretResource> {
            return localVarFp.updateSecret(secretId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} description 
         * @param {'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI'} type 
         * @param {Array<number>} [dependencies] 
         * @param {Array<number>} [otherDependencies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateService(serviceId: number, name: string, description: string, type: 'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI', dependencies?: Array<number>, otherDependencies?: Array<number>, options?: any): AxiosPromise<ServiceResource> {
            return localVarFp.updateService(serviceId, name, description, type, dependencies, otherDependencies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVolume(volumeId: number, name: string, size: string, mount: string, options?: any): AxiosPromise<VolumeResource> {
            return localVarFp.updateVolume(volumeId, name, size, mount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAppletouchIcon(projectId: number, inlineObject4?: InlineObject4, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.uploadAppletouchIcon(projectId, inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFavIcon(projectId: number, inlineObject3?: InlineObject3, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.uploadFavIcon(projectId, inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIcon(projectId: number, inlineObject2?: InlineObject2, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.uploadIcon(projectId, inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo(projectId: number, inlineObject1?: InlineObject1, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.uploadLogo(projectId, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMaskableIcon(projectId: number, inlineObject?: InlineObject, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.uploadMaskableIcon(projectId, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(options?: any): AxiosPromise<UserResource> {
            return localVarFp.user(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} serviceId 
     * @param {string} key 
     * @param {string} [value] 
     * @param {number} [secretId] 
     * @param {number} [parameterId] 
     * @param {boolean} [isExpression] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createEnvironmentVariable(serviceId: number, key: string, value?: string, secretId?: number, parameterId?: number, isExpression?: boolean, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createEnvironmentVariable(serviceId, key, value, secretId, parameterId, isExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} key 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createParameter(serviceId: number, key: string, value: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createParameter(serviceId, key, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {Array<string>} names 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createPrivileges(projectId: number, names: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createPrivileges(projectId, names, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} description 
     * @param {string} [adminUrl] 
     * @param {string} [shortname] 
     * @param {string} [themeColor] 
     * @param {string} [themeBackgroundColor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createProject(name: string, description: string, adminUrl?: string, shortname?: string, themeColor?: string, themeBackgroundColor?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createProject(name, description, adminUrl, shortname, themeColor, themeBackgroundColor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} name 
     * @param {boolean} isPublic 
     * @param {Array<number>} privileges 
     * @param {string} description 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createRole(projectId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createRole(projectId, name, isPublic, privileges, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createSecret(serviceId: number, key: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createSecret(serviceId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} name 
     * @param {string} description 
     * @param {'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI'} type 
     * @param {Array<number>} [dependencies] 
     * @param {Array<number>} [otherDependencies] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createService(projectId: number, name: string, description: string, type: 'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI', dependencies?: Array<number>, otherDependencies?: Array<number>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createService(projectId, name, description, type, dependencies, otherDependencies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {Array<string>} names 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createTags(projectId: number, names: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createTags(projectId, names, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} name 
     * @param {string} size 
     * @param {string} mount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createVolume(serviceId: number, name: string, size: string, mount: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createVolume(serviceId, name, size, mount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteEnvironment(id: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteEnvironment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} envId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteEnvironmentVariable(envId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteEnvironmentVariable(envId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parameterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteParameter(parameterId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteParameter(parameterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteProperty(propertyId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} secretId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteSecret(secretId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteSecret(secretId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteService(serviceId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteService(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} volumeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteVolume(volumeId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteVolume(volumeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getCompanyProjects(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getCompanyProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getDockerServiceProperty(propertyId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getDockerServiceProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getHealthcheckServiceProperty(propertyId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getHealthcheckServiceProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getK8sServiceProperty(propertyId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getK8sServiceProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getOAuth2ServiceProperty(propertyId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getOAuth2ServiceProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getPathServiceProperty(propertyId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getPathServiceProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getPortMappingServiceProperty(propertyId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getPortMappingServiceProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getProjectMembers(projectId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getProjectMembers(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getProjectPrivileges(projectId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getProjectPrivileges(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getProjectRoles(projectId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getProjectRoles(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getProjectServices(projectId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getProjectServices(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getRecipe(projectId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getRecipe(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getServiceEnvironmentVariables(serviceId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getServiceEnvironmentVariables(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getServiceParameters(serviceId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getServiceParameters(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getServiceProperties(serviceId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getServiceProperties(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getServiceSecrets(serviceId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getServiceSecrets(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getServiceVolumes(serviceId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getServiceVolumes(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getUserCompany(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getUserCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getUserProjects(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getUserProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} image 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postDockerServiceProperty(serviceId: number, image: string, version: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postDockerServiceProperty(serviceId, image, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} name 
     * @param {string} domain 
     * @param {number} [precedentEnvironment] 
     * @param {Array<number>} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postEnvironment(projectId: number, name: string, domain: string, precedentEnvironment?: number, tags?: Array<number>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postEnvironment(projectId, name, domain, precedentEnvironment, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} email 
     * @param {string} language 
     * @param {string} registerEmail 
     * @param {string} acceptEmail 
     * @param {string} from 
     * @param {string} registerUrl 
     * @param {string} redirectUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postInvite(projectId: number, email: string, language: string, registerEmail: string, acceptEmail: string, from: string, registerUrl: string, redirectUrl: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postInvite(projectId, email, language, registerEmail, acceptEmail, from, registerUrl, redirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {number} replicaCount 
     * @param {number} cpuLimit 
     * @param {number} memoryLimit 
     * @param {number} cpuRequest 
     * @param {number} memoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postK8sServiceProperty(serviceId: number, replicaCount: number, cpuLimit: number, memoryLimit: number, cpuRequest: number, memoryRequest: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postK8sServiceProperty(serviceId, replicaCount, cpuLimit, memoryLimit, cpuRequest, memoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postOAuth2ComponentServiceProperty(serviceId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postOAuth2ComponentServiceProperty(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} clientId 
     * @param {string} clientPassword 
     * @param {string} clientScope 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postOAuth2ServiceProperty(serviceId: number, clientId: string, clientPassword: string, clientScope: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postOAuth2ServiceProperty(serviceId, clientId, clientPassword, clientScope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {number} port 
     * @param {string} path 
     * @param {string} host 
     * @param {boolean} redirectWWW 
     * @param {string} [basicAuthUser] 
     * @param {string} [basicAuthPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postPathServiceProperty(serviceId: number, port: number, path: string, host: string, redirectWWW: boolean, basicAuthUser?: string, basicAuthPassword?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postPathServiceProperty(serviceId, port, path, host, redirectWWW, basicAuthUser, basicAuthPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {number} port 
     * @param {number} containerPort 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postPortMappingServiceProperty(serviceId: number, port: number, containerPort: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postPortMappingServiceProperty(serviceId, port, containerPort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} major 
     * @param {number} minor 
     * @param {number} patch 
     * @param {string} [cause] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postRelease(id: number, major: number, minor: number, patch: number, cause?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postRelease(id, major, minor, patch, cause, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postServiceHealthcheck(serviceId: number, url: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postServiceHealthcheck(serviceId, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postUser(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).postUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} environmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public promoteRelease(id: number, environmentId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).promoteRelease(id, environmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyName 
     * @param {string} firstname 
     * @param {string} lastname 
     * @param {'SOFTWARE_ENGINEER' | 'FREELANCE' | 'ENTREPRENEUR' | 'ARCHITECT' | 'CHIEF_TECHNOLOGY_OFFICER' | 'OTHER'} title 
     * @param {number} [companyPhone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putActivateUser(companyName: string, firstname: string, lastname: string, title: 'SOFTWARE_ENGINEER' | 'FREELANCE' | 'ENTREPRENEUR' | 'ARCHITECT' | 'CHIEF_TECHNOLOGY_OFFICER' | 'OTHER', companyPhone?: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putActivateUser(companyName, firstname, lastname, title, companyPhone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {string} image 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putDockerServiceProperty(propertyId: number, image: string, version: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putDockerServiceProperty(propertyId, image, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} configId 
     * @param {string} name 
     * @param {string} domain 
     * @param {number} [precedentEnvironment] 
     * @param {Array<number>} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putEnvironment(configId: number, name: string, domain: string, precedentEnvironment?: number, tags?: Array<number>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putEnvironment(configId, name, domain, precedentEnvironment, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} username 
     * @param {string} repository 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putGit(projectId: number, username: string, repository: string, password: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putGit(projectId, username, repository, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putHealthcheckServiceProperty(propertyId: number, url: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putHealthcheckServiceProperty(propertyId, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {number} replicaCount 
     * @param {number} cpuLimit 
     * @param {number} memoryLimit 
     * @param {number} cpuRequest 
     * @param {number} memoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putK8sServiceProperty(propertyId: number, replicaCount: number, cpuLimit: number, memoryLimit: number, cpuRequest: number, memoryRequest: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putK8sServiceProperty(propertyId, replicaCount, cpuLimit, memoryLimit, cpuRequest, memoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {string} clientId 
     * @param {string} clientPassword 
     * @param {string} clientScope 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putOAuth2ServiceProperty(propertyId: number, clientId: string, clientPassword: string, clientScope: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putOAuth2ServiceProperty(propertyId, clientId, clientPassword, clientScope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {number} port 
     * @param {string} path 
     * @param {string} host 
     * @param {boolean} redirectWWW 
     * @param {string} [basicAuthUser] 
     * @param {string} [basicAuthPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putPathServiceProperty(propertyId: number, port: number, path: string, host: string, redirectWWW: boolean, basicAuthUser?: string, basicAuthPassword?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putPathServiceProperty(propertyId, port, path, host, redirectWWW, basicAuthUser, basicAuthPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 
     * @param {number} port 
     * @param {number} containerPort 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putPortMappingServiceProperty(propertyId: number, port: number, containerPort: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putPortMappingServiceProperty(propertyId, port, containerPort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {number} privilegeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public setRolePrivilege(roleId: number, privilegeId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).setRolePrivilege(roleId, privilegeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} envId 
     * @param {string} key 
     * @param {string} [value] 
     * @param {number} [secretId] 
     * @param {number} [parameterId] 
     * @param {boolean} [isExpression] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateEnvironmentVariable(envId: number, key: string, value?: string, secretId?: number, parameterId?: number, isExpression?: boolean, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateEnvironmentVariable(envId, key, value, secretId, parameterId, isExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parameterId 
     * @param {string} key 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateParameter(parameterId: number, key: string, value: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateParameter(parameterId, key, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} description 
     * @param {string} [adminUrl] 
     * @param {string} [shortname] 
     * @param {string} [themeColor] 
     * @param {string} [themeBackgroundColor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateProject(projectId: number, description: string, adminUrl?: string, shortname?: string, themeColor?: string, themeBackgroundColor?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateProject(projectId, description, adminUrl, shortname, themeColor, themeBackgroundColor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {string} name 
     * @param {boolean} isPublic 
     * @param {Array<number>} privileges 
     * @param {string} description 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateRole(roleId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateRole(roleId, name, isPublic, privileges, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} secretId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateSecret(secretId: number, key: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateSecret(secretId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} name 
     * @param {string} description 
     * @param {'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI'} type 
     * @param {Array<number>} [dependencies] 
     * @param {Array<number>} [otherDependencies] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateService(serviceId: number, name: string, description: string, type: 'STANDARD' | 'MASTER_GATEWAY' | 'SLAVE_GATEWAY' | 'GUI', dependencies?: Array<number>, otherDependencies?: Array<number>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateService(serviceId, name, description, type, dependencies, otherDependencies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} volumeId 
     * @param {string} name 
     * @param {string} size 
     * @param {string} mount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateVolume(volumeId: number, name: string, size: string, mount: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateVolume(volumeId, name, size, mount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public uploadAppletouchIcon(projectId: number, inlineObject4?: InlineObject4, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).uploadAppletouchIcon(projectId, inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public uploadFavIcon(projectId: number, inlineObject3?: InlineObject3, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).uploadFavIcon(projectId, inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public uploadIcon(projectId: number, inlineObject2?: InlineObject2, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).uploadIcon(projectId, inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public uploadLogo(projectId: number, inlineObject1?: InlineObject1, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).uploadLogo(projectId, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public uploadMaskableIcon(projectId: number, inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).uploadMaskableIcon(projectId, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public user(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).user(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicControllerApi - axios parameter creator
 * @export
 */
export const PublicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {number} id 
         * @param {string} base64redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptRoleInvite: async (token: string, id: number, base64redirectUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('acceptRoleInvite', 'token', token)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('acceptRoleInvite', 'id', id)
            // verify required parameter 'base64redirectUrl' is not null or undefined
            assertParamExists('acceptRoleInvite', 'base64redirectUrl', base64redirectUrl)
            const localVarPath = `/public/invite/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (base64redirectUrl !== undefined) {
                localVarQueryParameter['base64redirectUrl'] = base64redirectUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticated: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/authenticated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} password 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (password: string, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'password' is not null or undefined
            assertParamExists('changePassword', 'password', password)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('changePassword', 'token', token)
            const localVarPath = `/public/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} base64loginUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserAccount: async (token: string, base64loginUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('confirmUserAccount', 'token', token)
            // verify required parameter 'base64loginUrl' is not null or undefined
            assertParamExists('confirmUserAccount', 'base64loginUrl', base64loginUrl)
            const localVarPath = `/public/confirmAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (base64loginUrl !== undefined) {
                localVarQueryParameter['base64loginUrl'] = base64loginUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectIcon: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectIcon', 'id', id)
            const localVarPath = `/public/projecticon/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProject: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getPublicProject', 'name', name)
            const localVarPath = `/public/project/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProjectRoles: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getPublicProjectRoles', 'name', name)
            const localVarPath = `/public/project/{name}/roles`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicePropertyTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/propertiestypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo3: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo4: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo5: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo6: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {number} role 
         * @param {string} redirectUrl 
         * @param {string} mail 
         * @param {string} language 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (email: string, password: string, role: number, redirectUrl: string, mail: string, language: string, from: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('register', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('register', 'password', password)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('register', 'role', role)
            // verify required parameter 'redirectUrl' is not null or undefined
            assertParamExists('register', 'redirectUrl', redirectUrl)
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('register', 'mail', mail)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('register', 'language', language)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('register', 'from', from)
            const localVarPath = `/public/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (redirectUrl !== undefined) {
                localVarQueryParameter['redirectUrl'] = redirectUrl;
            }

            if (mail !== undefined) {
                localVarQueryParameter['mail'] = mail;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (email: string, language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resetPassword', 'email', email)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('resetPassword', 'language', language)
            const localVarPath = `/public/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicControllerApi - functional programming interface
 * @export
 */
export const PublicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {number} id 
         * @param {string} base64redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptRoleInvite(token: string, id: number, base64redirectUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptRoleInvite(token, id, base64redirectUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticated(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticated(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} password 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(password: string, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(password, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} base64loginUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUserAccount(token: string, base64loginUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUserAccount(token, base64loginUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectIcon(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectIcon(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicProject(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicProject(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicProjectRoles(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicProjectRoles(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServicePropertyTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServicePropertyTypeResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServicePropertyTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo3(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo3(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo4(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo4(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo5(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo5(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo6(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo6(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {number} role 
         * @param {string} redirectUrl 
         * @param {string} mail 
         * @param {string} language 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(email: string, password: string, role: number, redirectUrl: string, mail: string, language: string, from: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(email, password, role, redirectUrl, mail, language, from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(email: string, language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(email, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicControllerApi - factory interface
 * @export
 */
export const PublicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {number} id 
         * @param {string} base64redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptRoleInvite(token: string, id: number, base64redirectUrl: string, options?: any): AxiosPromise<void> {
            return localVarFp.acceptRoleInvite(token, id, base64redirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticated(options?: any): AxiosPromise<boolean> {
            return localVarFp.authenticated(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} password 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(password: string, token: string, options?: any): AxiosPromise<string> {
            return localVarFp.changePassword(password, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} base64loginUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserAccount(token: string, base64loginUrl: string, options?: any): AxiosPromise<void> {
            return localVarFp.confirmUserAccount(token, base64loginUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectIcon(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getProjectIcon(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProject(name: string, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.getPublicProject(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProjectRoles(name: string, options?: any): AxiosPromise<Array<RoleResource>> {
            return localVarFp.getPublicProjectRoles(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicePropertyTypes(options?: any): AxiosPromise<Array<ServicePropertyTypeResource>> {
            return localVarFp.getServicePropertyTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo1(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo2(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo3(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo4(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo4(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo5(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo5(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo6(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo6(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {number} role 
         * @param {string} redirectUrl 
         * @param {string} mail 
         * @param {string} language 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(email: string, password: string, role: number, redirectUrl: string, mail: string, language: string, from: string, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.register(email, password, role, redirectUrl, mail, language, from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(email: string, language: string, options?: any): AxiosPromise<string> {
            return localVarFp.resetPassword(email, language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicControllerApi - object-oriented interface
 * @export
 * @class PublicControllerApi
 * @extends {BaseAPI}
 */
export class PublicControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} token 
     * @param {number} id 
     * @param {string} base64redirectUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public acceptRoleInvite(token: string, id: number, base64redirectUrl: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).acceptRoleInvite(token, id, base64redirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public authenticated(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).authenticated(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} password 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public changePassword(password: string, token: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).changePassword(password, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {string} base64loginUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public confirmUserAccount(token: string, base64loginUrl: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).confirmUserAccount(token, base64loginUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getProjectIcon(id: number, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getProjectIcon(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getPublicProject(name: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getPublicProject(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getPublicProjectRoles(name: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getPublicProjectRoles(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getServicePropertyTypes(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getServicePropertyTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo1(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo2(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo3(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo4(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo4(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo5(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo5(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo6(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo6(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} password 
     * @param {number} role 
     * @param {string} redirectUrl 
     * @param {string} mail 
     * @param {string} language 
     * @param {string} from 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public register(email: string, password: string, role: number, redirectUrl: string, mail: string, language: string, from: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).register(email, password, role, redirectUrl, mail, language, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public resetPassword(email: string, language: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).resetPassword(email, language, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuControllerApi - axios parameter creator
 * @export
 */
export const SuControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationToken: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('confirmationToken', 'email', email)
            const localVarPath = `/su/confirmationToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdentity: async (email: string, password: string, roles: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('createIdentity', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('createIdentity', 'password', password)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('createIdentity', 'roles', roles)
            const localVarPath = `/su/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentitiesPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/su/identities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentityByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getIdentityByEmail', 'email', email)
            const localVarPath = `/su/identity/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/su/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} identityId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIdentityRole: async (identityId: number, roleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identityId' is not null or undefined
            assertParamExists('setIdentityRole', 'identityId', identityId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('setIdentityRole', 'roleId', roleId)
            const localVarPath = `/su/identity/{identityId}/roles`
                .replace(`{${"identityId"}}`, encodeURIComponent(String(identityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} identityId 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentity: async (identityId: number, roles: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identityId' is not null or undefined
            assertParamExists('updateIdentity', 'identityId', identityId)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('updateIdentity', 'roles', roles)
            const localVarPath = `/su/identity/{identityId}`
                .replace(`{${"identityId"}}`, encodeURIComponent(String(identityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuControllerApi - functional programming interface
 * @export
 */
export const SuControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmationToken(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmationToken(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIdentity(email: string, password: string, roles: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIdentity(email, password, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdentitiesPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageIdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdentitiesPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdentityByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdentityByEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} identityId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setIdentityRole(identityId: number, roleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setIdentityRole(identityId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} identityId 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdentity(identityId: number, roles: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdentity(identityId, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuControllerApi - factory interface
 * @export
 */
export const SuControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationToken(email: string, options?: any): AxiosPromise<string> {
            return localVarFp.confirmationToken(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdentity(email: string, password: string, roles: Array<number>, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.createIdentity(email, password, roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentitiesPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageIdentityResource> {
            return localVarFp.getIdentitiesPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentityByEmail(email: string, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.getIdentityByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles1(options?: any): AxiosPromise<Array<RoleResource>> {
            return localVarFp.getRoles1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} identityId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIdentityRole(identityId: number, roleId: number, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.setIdentityRole(identityId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} identityId 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentity(identityId: number, roles: Array<number>, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.updateIdentity(identityId, roles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuControllerApi - object-oriented interface
 * @export
 * @class SuControllerApi
 * @extends {BaseAPI}
 */
export class SuControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuControllerApi
     */
    public confirmationToken(email: string, options?: AxiosRequestConfig) {
        return SuControllerApiFp(this.configuration).confirmationToken(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} password 
     * @param {Array<number>} roles 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuControllerApi
     */
    public createIdentity(email: string, password: string, roles: Array<number>, options?: AxiosRequestConfig) {
        return SuControllerApiFp(this.configuration).createIdentity(email, password, roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuControllerApi
     */
    public getIdentitiesPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return SuControllerApiFp(this.configuration).getIdentitiesPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuControllerApi
     */
    public getIdentityByEmail(email: string, options?: AxiosRequestConfig) {
        return SuControllerApiFp(this.configuration).getIdentityByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuControllerApi
     */
    public getRoles1(options?: AxiosRequestConfig) {
        return SuControllerApiFp(this.configuration).getRoles1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} identityId 
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuControllerApi
     */
    public setIdentityRole(identityId: number, roleId: number, options?: AxiosRequestConfig) {
        return SuControllerApiFp(this.configuration).setIdentityRole(identityId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} identityId 
     * @param {Array<number>} roles 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuControllerApi
     */
    public updateIdentity(identityId: number, roles: Array<number>, options?: AxiosRequestConfig) {
        return SuControllerApiFp(this.configuration).updateIdentity(identityId, roles, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identity(options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.identity(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public identity(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).identity(options).then((request) => request(this.axios, this.basePath));
    }
}


