import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import styled from "styled-components";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ReactComponent as Logo } from '../assets/logo-name.svg';

type NavigationBarProps = {
  homeURL: string,
  loginURL: string|undefined,
  isAuthenticated: boolean,
  logoutUrl: string|undefined,
  logoUrl: string | undefined,
  logout: () => void,
}

const ToolbarButton = styled(Button)`
    color: white;
  `;

const BoxRightJustified = styled(Box)`
    margin-left: auto;
  `;

const Stroke = styled.div({
  color: 'white',
  textShadow: '1px 1px 2px #666666;',
})

const NavigationBar = ({ homeURL, loginURL, isAuthenticated, logoutUrl, logoUrl, logout }: NavigationBarProps) => {
  const [anchorUser, setAnchorUser] = React.useState<null | HTMLElement>(null)

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUser(event.currentTarget)
  };

  const handleUserClose = () => {
    setAnchorUser(null)
  };

  const openUser = Boolean(anchorUser);

  const handleLogout = () => {
    logout()
  };

  return (
    <div >
      <AppBar position="sticky" elevation={0}>
        <Toolbar sx={{ paddingTop: { xs: '0.5rem', sm: '1.25rem' }, paddingBottom: { xs: '0.5rem', sm: '1.25rem' } }}>
          <Link to={homeURL} aria-label="Administrate your Kodo projects">
            <Box width='8rem'>
              {
                logoUrl ? <img src={logoUrl}/> : <Logo fill='white' />
              }
              </Box>
          </Link>

          <BoxRightJustified>

            {!isAuthenticated && loginURL && (
              <div>
                <ToolbarButton onClick={() => window.location.href = loginURL}>
                  <Stroke>
                    <FormattedMessage id="nav.signin-button" />
                  </Stroke>
                </ToolbarButton>
              </div>
            )}
          </BoxRightJustified>

          {isAuthenticated && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-user"
                aria-haspopup="true"
                onClick={handleUserMenu}
                color="inherit">
                <AccountCircle />
              </IconButton>

              <Menu
                id="menu-user"
                anchorEl={anchorUser}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                open={openUser}
                onClose={handleUserClose}>
                <MenuItem onClick={handleLogout} style={{display: logoutUrl ? 'block' : 'none'}}>
                  <FormattedMessage id="nav.logout-button"
                    defaultMessage="Log Out"
                    description="Log Out Button Text" />
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavigationBar