import { PublicControllerApi, AdminControllerApi, UserControllerApi } from './generated/launchpad-api';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import axios, { AxiosRequestConfig, AxiosError } from 'axios'

interface RetryConfig extends AxiosRequestConfig {
    retried: boolean;
}

export const withCredentialsRetries: RetryConfig = {
    retried: false,
    withCredentials: true
};

export class Environment {

    private static instance: Environment;
    private constructor(gatewayURL: string, launchpadBasePath: string, configuration: [string, string, string][], authorizeUrl: string, clientId: string, callback: string, revokeTokenUrl: string, logoutUrl: string, logoutRedirectUrl: string, projectName: string) {
        this._launchpadPublicControllerApi = new PublicControllerApi(undefined, launchpadBasePath, axios)
        this._launchpadUserControllerApi = new UserControllerApi(undefined, launchpadBasePath, axios)
        this._launchpadAdminControllerApi = new AdminControllerApi(undefined, launchpadBasePath, axios)
        this._configuration = configuration
        this._loginUrl = authorizeUrl + "?response_type=code&client_id=" + clientId + "&redirect_uri=" + callback + "?client_id=" + clientId
        this._revokeTokenUrl = revokeTokenUrl
        this._logoutUrl = logoutUrl
        this._logoutRedirectUrl = logoutRedirectUrl
        this._projectName = projectName
        this._launchpadBasePath = launchpadBasePath;
        this._gatewayURL = gatewayURL

        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error?.response?.status === 401) {
                const { config } = error;

                if (!config || config.retried) {
                    window.location.href = this.loginUrl
                } else {
                    config.retried = true
                    // try refresh token
                    return axios.get(this.gatewauUrl + "/refreshtoken", { withCredentials: true }).then((result) => {
                        return axios.request(error.config);
                    }).catch(err => {
                        window.location.href = this.loginUrl
                    })
                }
            }

            return Promise.reject(error);
        });
    }

    private _launchpadPublicControllerApi: PublicControllerApi
    private _launchpadAdminControllerApi: AdminControllerApi
    private _launchpadUserControllerApi: UserControllerApi
    private _configuration: [string, string, string][]
    private _loginUrl: string
    private _revokeTokenUrl: string
    private _logoutUrl: string
    private _logoutRedirectUrl: string
    private _projectName: string;
    private _launchpadBasePath: string;
    private _gatewayURL: string;


    public get launchpadPublicControllerApi() {
        return this._launchpadPublicControllerApi;
    }

    public get launchpadAdminControllerApi() {
        return this._launchpadAdminControllerApi;
    }

    public get launchpadUserControllerApi() {
        return this._launchpadUserControllerApi;
    }

    public get configuration() {
        return this._configuration;
    }

    public get loginUrl() {
        return this._loginUrl;
    }


    public get revokeTokenUrl() {
        return this._revokeTokenUrl
    }

    public get logoutUrl() {
        return this._logoutUrl;
    }

    public get logoutRedirectUrl() {
        return this._logoutRedirectUrl;
    }

    public get projectName() {
        return this._projectName;
    }

    public get launchpadBasePath() {
        return this._launchpadBasePath;
    }

    public get gatewauUrl() {
        return this._gatewayURL;
    }

    private initSentry = (sentryDNS: string, sentryEnvironment: string, version: string) => {
        Sentry.init({
            dsn: sentryDNS,
            environment: sentryEnvironment,
            release: version,
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }

    public static async getInstance(): Promise<Environment> {
        if (!Environment.instance) {
            return axios.get("/config")
                .then((result) => {
                    Environment.instance = new Environment(result.data.LAUNCHPAD_API_GATEWAY,
                        result.data.LAUNCHPAD_API_GATEWAY + "/launchpad",
                        JSON.parse(result.data.CONFIGURATION),
                        Boolean(result.data.AUTHORIZE_URL) ? result.data.AUTHORIZE_URL : result.data.LAUNCHPAD_API_GATEWAY + '/launchpad/oauth2/authorize',
                        result.data.CLIENT_ID,
                        result.data.LAUNCHPAD_API_GATEWAY + "/oauthcallback",
                        result.data.LAUNCHPAD_API_GATEWAY + "/refreshtoken/revoke",
                        result.data.LAUNCHPAD_API_GATEWAY + "/logoff?logout_redirect=" + result.data.LOGOUT_REDIRECT_URL,
                        result.data.LOGOUT_REDIRECT_URL,
                        result.data.LAUNCHPAD_PROJECT_NAME)

                    if (result.data.SENTRY_DSN && result.data.SENTRY_ENVIRONMENT && process.env.VERSION) {
                        Environment.instance.initSentry(result.data.SENTRY_DSN, result.data.SENTRY_ENVIRONMENT, process.env.VERSION)
                    }

                    return Environment.instance;
                })
        }

        return Environment.instance;
    }

    public logout() {
        axios.post(this.revokeTokenUrl, null, { withCredentials: true }).then(result => {
            window.location.href = this.logoutUrl
        })
    }
}