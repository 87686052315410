import React from 'react';
import axios from 'axios'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

type ErrorHandlerProps = {
  loginURL: string | undefined,
  configuration: Array<[string, string, string]> | undefined,
}

const ErrorHandler = ({ loginURL, configuration }: ErrorHandlerProps) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleComponentError = React.useCallback((event: any) => {
    if (configuration && configuration?.find((value) => new URL(value[1]).hostname === new URL(event.origin).hostname)) {
      if (event.data.type == "error") {
        handleChildrenError(JSON.parse(event.data.content))
      }
    }
  }, [configuration]);

  // handle 401 (authenticate) and log others
  const handleChildrenError = (error: any) => {
    if (error?.status === 401 && loginURL) {
      window.location.href = loginURL
    } else {
      setOpen(true)
      setMessage(error.toString())
    }
  }

  // log all non 401 errors
  const handleShellError = (error: any) => {
    if (error?.response?.status !== 401) {
      setOpen(true)
      setMessage(error.toString())
    }
  }

  React.useEffect(() => {
    if (configuration) {
      window.addEventListener('message', handleComponentError)
      return () => {
        window.removeEventListener("message", handleComponentError);
      };
    }
  }, [configuration]);

  // error handling for non 401 errors
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    handleShellError(error)
    return Promise.reject(error);;
  });

  return (
    <Snackbar open={open} autoHideDuration={6000} onAbort={() => setOpen(false)} onClose={() => setOpen(false)}>
      <div>
        <MuiAlert elevation={6} variant="filled" severity='error'>{message}</MuiAlert>
      </div>
    </Snackbar>
  );
}

export default ErrorHandler;