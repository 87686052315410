import React, { Suspense } from 'react';
import ReactLoading from "react-loading"
import { FormattedMessage } from 'react-intl'
import { Grid, Tabs, Tab, TabsProps } from "@mui/material"
import { styled } from '@mui/material/styles';

const ComponentContainer = React.lazy(() => import('../ComponentContainer/ComponentContainer'));

type RootProps = {
  configuration: Array<[string, string,string]> | undefined,
}

const Root = ({ configuration }: RootProps) => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <div>
      {configuration &&  (
        configuration.length > 1 ? 
        <Tabs style={{ display: configuration.length > 1 ? "bloc" : 'none'}} value={tabIndex} onChange={handleChange} textColor="inherit"
        indicatorColor="secondary">
          {configuration.map((value, index) => <Tab key={index} label={value[0]} />)}
        </Tabs>
        : null
      )}

      {
        configuration && configuration.map((value, index) =>
          <div style={{ width: '100%' }} key={index}>

            {index === tabIndex ?
              <Suspense fallback={
                <Grid container textAlign={'center'} alignSelf={'center'} alignItems={'center'} alignContent={'center'} justifySelf={'center'} justifyItems={'center'} justifyContent={'center'}>
                  <Grid item>
                    <ReactLoading type={'bars'} color={'#BC3143'} height={60} width={60} />
                    <FormattedMessage id="root.loading" />
                  </Grid>
                </Grid>}>
                <ComponentContainer url={value[1]} />
              </Suspense> : null}
          </div>

        )}
    </div>
  );
}

export default Root
