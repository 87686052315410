import React from 'react';
import { BrowserRouter } from "react-router-dom";
import NavigationBar from '../Nav/Nav';
import { Route, Routes } from 'react-router-dom';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import Root from '../Root/Root';
import { IntlProvider } from 'react-intl';
import locale_de from "../translations/de.json";
import locale_en from "../translations/en.json";
import locale_fr from "../translations/fr.json";
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { IdentityResource, ProjectResource } from '../generated/launchpad-api';
import { Environment, withCredentialsRetries } from '../Environment';
import { Theme } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { useTheme } from "@mui/material";
import './fonts.css'

const languages = new Map([
  ["de", locale_de],
  ["en", locale_en],
  ["fr", locale_fr]
]);

function getLanguage(language: string): string {
  return languages.has(language) ? language : languages.has(language.split(/[-_]/)[0]) ? language.split(/[-_]/)[0] : 'en'
}

const language = getLanguage(navigator.language)

const kodoTheme = (primaryColor: string, secondaryColor: string) => {
  return createTheme({
    typography: {
      fontFamily: ['"Helvetica Neue LT W05 65 Medium"', 'helvetica', 'Arial'].join(','),
      h1: {
        fontFamily: ['"Helvetica Neue LT W05_77 Bd Cn"', 'helvetica', 'Arial'].join(','),
        fontSize: '3rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        textAlign: 'center',
      },
      h2: {
        fontFamily: ['"Helvetica Neue LT W05_77 Bd Cn"', 'helvetica', 'Arial'].join(','),
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        textAlign: 'center',
      },
      h3: {
        fontFamily: ['"Helvetica Neue LT W05_77 Bd Cn"', 'helvetica', 'Arial'].join(','),
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
      },
      h4: {
        fontFamily: ['"Helvetica Neue LT W05_47 Lt Cn"', 'helvetica', 'Arial'].join(','),
        marginBottom: '1rem',
      },
      h5: {
        fontFamily: ['"Helvetica Neue LT W05 75 Bold"', 'helvetica', 'Arial'].join(','),
        fontWeight: 'bold',
        marginBottom: '1rem',
      },
      body1: {
        fontFamily: ['"Helvetica Neue LT W05_47 Lt Cn"', 'helvetica', 'Arial'].join(','),
        marginBottom: '1rem',
        flexWrap: 'wrap',
      },
      body2: {
        fontFamily: ['"Helvetica Neue LT W05_47 Lt Cn"', 'helvetica', 'Arial'].join(','),
        marginBottom: '1rem',
        flexWrap: 'wrap',
      },
      button: {
        fontFamily: ['"Helvetica Neue LT W05_77 Bd Cn"', 'helvetica', 'Arial'].join(','),
        fontWeight: 'bold',
      },
    },
    palette: {
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: primaryColor,
            '& .MuiTabs-indicator': {
              backgroundColor: 'white',
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: 'white',
            '&.Mui-selected': {
              color: 'white',
            },
            '&:hover': {
              color: 'white',
            },
          },
        }
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: { backgroundColor: primaryColor },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            color: 'white',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 30,
            fontWeight: 'bold',
            fontFamily: ['"Helvetica Neue LT W05 75 Bold"', 'helvetica', 'Arial'].join(','),
          },
        },
      },
    },
  });
}


const App = () => {
  const [loginUrl, setLoginURL] = React.useState<string | undefined>(undefined)
  const [logoutUrl, setLogoutURL] = React.useState<string | undefined>(undefined)
  const [configuration, setConfiguration] = React.useState<[string, string, string][] | undefined>(undefined)
  const [identity, setIdentity] = React.useState<IdentityResource | undefined>(undefined)
  const [environment, setEnvironment] = React.useState<Environment | undefined>(undefined)
  const [projects, setProjects] = React.useState<[string, string, string][] | undefined>(undefined)
  const [project, setProject] = React.useState<ProjectResource | undefined>(undefined)
  const [theme, setTheme] = React.useState<Theme>(useTheme())
  const [display, setDisplay] = React.useState<string>('none')

  const logout = () => {
    environment?.logout()
  }


  React.useEffect(() => {
    Environment.getInstance().then((environment) => {
      setLoginURL(environment.loginUrl)
      setLogoutURL(environment.logoutUrl)
      setEnvironment(environment)
    })
  }, []);

  React.useEffect(() => {
    if(project && environment) {
      setTheme(kodoTheme(project.themeColor!, project.themeColor!))

      document.title = project.shortName ?? project.name!

      if (project.favIcon?.path) {
        let favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement
        if (favicon !== null) {
          favicon.href = environment.launchpadBasePath + project.favIcon?.path
        } else {
          var link = document.createElement("link") as HTMLLinkElement
          link.rel = "icon"
          link.href = environment.launchpadBasePath + project.favIcon?.path
          document.head.appendChild(link)
        }
      }

      if(project.appleTouchIcon?.path) {
        let appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]') as HTMLLinkElement
        if (appleTouchIcon !== null) {
          appleTouchIcon.href = environment.launchpadBasePath + project.appleTouchIcon?.path
        }
      }

      let description = document.querySelector('meta[name="description"]') as HTMLMetaElement
      if (description !== null) {
        description.content = project.description!
      }

      let themeColor = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement
      if (themeColor !== null) {
        themeColor.content = project.themeColor!
      }

      setDisplay('block')

    }
  }, [project]);

  React.useEffect(() => {
    if(environment) {

      environment.launchpadUserControllerApi.identity(withCredentialsRetries).then((result) => {
        setIdentity(result.data)
      })

      environment.launchpadPublicControllerApi.getPublicProject(environment.projectName).then((result) => {
        setProject(result.data)
      }).catch(() => {
        setDisplay('block')
      })
    }
  }, [environment]);

  React.useEffect(() => {
    Environment.getInstance().then((environment) => {

      const privileges = identity?.roles?.map(t => t?.privileges ?? [])?.reduce((accumulator, value) => accumulator.concat(value), []);
      var filteredConfiguration = environment.configuration.filter(item => privileges?.find(privilege => privilege.name === item[2]))
      let items = projects ? filteredConfiguration.concat(projects) : filteredConfiguration

      setConfiguration(items)
    })
  }, [projects, identity]);

  const handleComponentAdminUrl = React.useCallback((event: any) => {
    if (configuration && configuration?.find((value) => new URL(value[1]).hostname === new URL(event.origin).hostname)) {
      if (event.data.type == "projects") {
        setProjects(event.data.content)
      }
    }
  }, [configuration]);

  React.useEffect(() => {
    if (configuration) {
      window.addEventListener('message', handleComponentAdminUrl)
      return () => {
        window.removeEventListener("message", handleComponentAdminUrl);
      };
    }
  }, [configuration]);

  return (
    <div>
      <MuiThemeProvider theme={useTheme()}>
        <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <IntlProvider locale={language} messages={languages.get(language)}>
              <div style={{ display: display }}>
                <NavigationBar logout={logout} isAuthenticated={identity !== undefined} homeURL={"/"} loginURL={loginUrl} logoutUrl={logoutUrl} logoUrl={environment && project?.logo ? environment.launchpadBasePath + project?.logo?.path : undefined} />
              </div>
            <Routes>
              <Route path="/" element={<Root configuration={configuration} />} />
            </Routes>
            <ErrorHandler loginURL={loginUrl} configuration={configuration}/>
          </IntlProvider>
        </BrowserRouter>
      </MuiThemeProvider>
      </MuiThemeProvider>
    </div>
  )
}

export default App